import { flow, getParent, Instance, IStateTreeNode, types, toGenerator } from "mobx-state-tree"

import { withEnvironment } from "./extensions/with-environment"
import { MembershipRequest, MembershipRequestModel } from "../models/membership-request"
import { withSessionStore } from "./session-store"
import { withUserStore } from "./user-store"
import {
  GroupMembersSearchResult,
  MembershipApi,
  MembershipQuery,
} from "../services/api/membership-api"
import { RootStoreModel } from "./root-store"
import { SearchApi, SearchFilterType, SearchRequest } from "../services"

export const SEARCH_PAGE_SIZE = 50

export const MembershipRequestStoreModel = types
  .model("MembershipRequestStore")
  .props({
    membershipRequests: types.map(MembershipRequestModel),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withUserStore)
  .actions((self) => ({
    putMembershipRequests: (membershipRequests: MembershipRequest[]) => {
      return membershipRequests.map((m) => self.membershipRequests.put(m))
    },
  }))
  .actions((self) => ({
    fetchCurrentUserMembershipRequestForEntityId: flow(function* (entityId: string) {
      const membershipApi = new MembershipApi(self.environment.api)
      const result = yield* toGenerator(membershipApi.getMembershipRequest(entityId))
      if (result.membershipRequest) {
        return self.putMembershipRequests([result.membershipRequest])[0]
      }
      return null
    }),
    fetchPendingMembershipRequests: flow(function* (entityId: string) {
      const membershipApi = new MembershipApi(self.environment.api)
      const result = yield* toGenerator(membershipApi.getPendingRequestsByEntity(entityId))

      // delete existing records for entity
      for (const membershipRequest of self.membershipRequests.values()) {
        if (!membershipRequest) {
          continue
        }
        if (
          membershipRequest.groupId === entityId ||
          membershipRequest.organizationId === entityId
        ) {
          self.membershipRequests.delete(membershipRequest.id)
        }
      }
      return self.putMembershipRequests(result.membershipRequests)
    }),
  }))
  .actions((self) => ({
    requestGroupMembership: flow(function* (entityId: string) {
      const membershipApi = new MembershipApi(self.environment.api)
      yield membershipApi.requestGroupMembership(entityId)
      yield self.fetchCurrentUserMembershipRequestForEntityId(entityId)
      return true
    }),
    acceptMembershipRequest: flow(function* (membershipRequestId: string) {
      const membershipRequest = self.membershipRequests.get(membershipRequestId)
      const membershipApi = new MembershipApi(self.environment.api)

      yield membershipApi.acceptMembershipRequest(membershipRequestId)

      // reload all entity membership requests // TODO inefficient
      if (membershipRequest) {
        yield self.fetchPendingMembershipRequests(membershipRequest.entityId)
      }

      return true
    }),
    rejectMembershipRequest: flow(function* (membershipRequestId: string) {
      const membershipRequest = self.membershipRequests.get(membershipRequestId)
      const membershipApi = new MembershipApi(self.environment.api)

      yield membershipApi.rejectMembershipRequest(membershipRequestId)

      // reload all entity membership requests // TODO inefficient
      if (membershipRequest) {
        yield self.fetchPendingMembershipRequests(membershipRequest.entityId)
      }

      return true
    }),
    searchOrganizationMembers: flow(function* (organizationId: string, query: MembershipQuery) {
      const membershipApi = new MembershipApi(self.environment.api)
      const results = yield* toGenerator(
        membershipApi.searchOrganizationMembers(organizationId, query),
      )

      self.userStore.putUsers(results.users)

      return { ...results, users: self.userStore.putUsers(results.users) }
    }),
    searchGroupMembers: flow(function* (groupId: string, query: MembershipQuery) {
      const searchApi = new SearchApi(self.environment.api)
      const request: SearchRequest = {
        // map from query
        filters: [
          {
            type: SearchFilterType.ByGroup,
            value: groupId,
          },
          {
            type: SearchFilterType.ByGroupMemberRole,
            value: query.roleIds,
          },
        ],
        pageNumber: query.pageNumber || 1,
        pageSize: query.pageSize || SEARCH_PAGE_SIZE,
        sortType: query.sortType,
        sortOrder: query.sortOrder,
        query: query.textQuery || "",
      }
      const memberSearchResult = yield* toGenerator(searchApi.searchGroupMembers(request))
      const mappedResults: GroupMembersSearchResult = {
        totalCount: memberSearchResult.totalCount,
        numPages: memberSearchResult.numPages,
        pageNumber: memberSearchResult.pageNumber,
        results: memberSearchResult.results,
        users: memberSearchResult.users,
      }

      return { ...mappedResults, users: self.userStore.putUsers(mappedResults.users) }
    }),
  }))
  .views((self) => ({
    membershipRequestsByEntityId: (entityId) => {
      return Array.from(self.membershipRequests.values()).filter(
        (membershipRequest) =>
          membershipRequest &&
          (membershipRequest.groupId === entityId || membershipRequest.organizationId === entityId),
      )
    },
  }))

export type MembershipRequestStore = Instance<typeof MembershipRequestStoreModel>
export const withMembershipRequestStore = (self: IStateTreeNode) => ({
  views: {
    get membershipRequestStore(): MembershipRequestStore {
      return getParent<Instance<typeof RootStoreModel>>(self).membershipRequestStore
    },
  },
})
