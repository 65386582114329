import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { PublicUser } from "../../models/public-user"
import { Organization } from "../../models/organization"
import { Group } from "../../models/group"
import { Pitch } from "../../models/pitch"
import { Playlist } from "../../models/playlist"
import { PitchWithPlaylistMetadata } from "./playlist-api"
import { Program } from "../../models/program"
import { StudioPitch } from "../../models/studio-pitch-info"
import { Reaction } from "../../models/reaction"
import { GroupMembersSearchResult } from "./membership-api"

const API_PATH = "api/Search/"

export enum SearchTagType {
  Groups = "Groups",
  Users = "Users",
  GroupsAndUsers = "GroupsAndUsers",
}

export enum SearchFilterType {
  ByOrganization = 0,
  ByGroup = 1,
  ByUser = 2,
  // TODO: fix numbering once API is updated
  WithinPlaylist = 4,
  ContainsPitch = 5,
  ByEntities = 6,
  ExcludeSelf = 8,
  ContainsPlaylist = 9,
  WithinProgram = 10,
  HasWatchedValue = 11,
  ReactedToPitch = 12,
  MatchesReactionType = 13,
  ByGroupMemberRole = 14,
}

export enum SearchSortType {
  CreatedDate,
  Alphabetical,
  Count,
  LastSubmittedDate,
  UpdatedDate,
  Ordinal,
  DateAdded,
  LastWatchedDate,
  AlphabeticalByCreator,
  ProfilePitchUpdated,
  ReactionDate,
  ByGroupMemberRole,
}

export enum SearchSortOrder {
  Ascending,
  Descending,
}

export interface SearchFilter {
  type: SearchFilterType
  value: any
}

export interface SearchRequest {
  query: string
  filters: SearchFilter[]
  pageSize: number
  pageNumber: number
  sortType?: SearchSortType
  sortOrder?: SearchSortOrder
}

export interface SearchResult<T> {
  totalCount: number
  numPages: number
  pageNumber: number
  results: T[]
}

export interface GroupSearchResult extends SearchResult<Group> {
  organizations: Organization[]
}

export interface ReactionSearchResult {
  reaction: Reaction
  user: PublicUser
}

export interface PitchSearchModel {
  pitch: Pitch
  // Only present if using WithinPlaylist filter
  playlistPitch?: PitchWithPlaylistMetadata
}

export class SearchApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async searchUsers(request: SearchRequest): Promise<SearchResult<PublicUser>> {
    const response: ApiResponse<SearchResult<PublicUser>> = await this.api.apisauce.post(
      API_PATH + "users",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchUsers", response)
    }
    return response.data
  }

  async searchReactions(request: SearchRequest): Promise<SearchResult<ReactionSearchResult>> {
    const response: ApiResponse<SearchResult<ReactionSearchResult>> = await this.api.apisauce.post(
      API_PATH + "reactions",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchReactions", response)
    }
    return response.data
  }

  async searchGroups(request: SearchRequest): Promise<GroupSearchResult> {
    const response: ApiResponse<GroupSearchResult> = await this.api.apisauce.post(
      API_PATH + "groups",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchGroups", response)
    }
    return response.data
  }

  async searchGroupMembers(request: SearchRequest): Promise<GroupMembersSearchResult> {
    const response: ApiResponse<GroupMembersSearchResult> = await this.api.apisauce.post(
      API_PATH + "groupUsers",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchGroupUsers", response)
    }
    return response.data
  }

  async searchPitches(request: SearchRequest): Promise<SearchResult<PitchSearchModel>> {
    const response: ApiResponse<SearchResult<PitchSearchModel>> = await this.api.apisauce.post(
      API_PATH + "resource/pitch",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchPitches", response)
    }
    return response.data
  }

  async searchStudioPitches(request: SearchRequest): Promise<SearchResult<StudioPitch>> {
    const response: ApiResponse<SearchResult<StudioPitch>> = await this.api.apisauce.post(
      API_PATH + "resource/studiopitch",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchStudioPitches", response)
    }
    return response.data
  }

  async searchPlaylistPitches(request: SearchRequest): Promise<SearchResult<PitchSearchModel>> {
    const response: ApiResponse<SearchResult<PitchSearchModel>> = await this.api.apisauce.post(
      API_PATH + "resource/pitch/playlist",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchStudioPitches", response)
    }
    return response.data
  }

  async searchPlaylists(request: SearchRequest): Promise<SearchResult<Playlist>> {
    const response: ApiResponse<SearchResult<Playlist>> = await this.api.apisauce.post(
      API_PATH + "resource/playlist",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchPlaylists", response)
    }
    return response.data
  }

  async searchSubmissionPlaylists(request: SearchRequest): Promise<SearchResult<Playlist>> {
    const response: ApiResponse<SearchResult<Playlist>> = await this.api.apisauce.post(
      API_PATH + "resource/playlist/submission",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchPlaylists", response)
    }
    return response.data
  }

  async searchPrograms(request: SearchRequest): Promise<SearchResult<Program>> {
    const response: ApiResponse<SearchResult<Program>> = await this.api.apisauce.post(
      API_PATH + "resource/program",
      request,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("SearchApi.searchPrograms", response)
    }
    return response.data
  }
}
