import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { MembershipRequest } from "../../models/membership-request"
import { PublicUser } from "../../models/public-user"
import { SearchSortOrder, SearchSortType } from "./search-api"

export type MembershipRequestResult = { membershipRequest?: MembershipRequest }

export type MembershipRequestsResult = { membershipRequests: MembershipRequest[] }

export type OrganizationMembersSearchResult = {
  pageNumber: number
  numPages: number
  totalCount: number
  results: OrganizationMembershipSearchResults[]
  users: PublicUser[]
}

export type GroupMembersSearchResult = {
  pageNumber: number
  numPages: number
  totalCount: number
  results: GroupMembershipSearchResults[]
  users: PublicUser[]
}

export type OrganizationMembershipSearchResults = {
  organizationId: string
  userId: string
  organizationUser?: { organizationUserId: string; organizationRoleId: string }
  createdUtc: Date
  name: string
  groups: { groupId: string; organizationRoldId: string; createdUtc: Date }[]
}

export type GroupMembershipSearchResults = {
  groupId: string
  organizationId: string
  groupUserId: string
  userId: string
  organizationRoleId: string
  createdUtc: Date
  name: string
}

export type MembershipQuery = {
  pageNumber?: number
  pageSize?: number
  sortType?: SearchSortType
  sortOrder?: SearchSortOrder
  roleIds?: string[]
  groupIds?: string[]
  textQuery?: string
}

const API_PATH = "api/Membership/"

export class MembershipApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getMembershipRequest(entityId: string): Promise<MembershipRequestResult> {
    const response: ApiResponse<MembershipRequest> = await this.api.apisauce.get(
      `${API_PATH}entity/${entityId}/user`,
    )
    if (!response.ok) {
      throw new ApiError("MembershipApi.getMembershipRequest", response)
    }
    return {
      membershipRequest: response.data,
    }
  }

  async requestGroupMembership(groupId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}group/${groupId}/request-access`,
    )
    if (!response.ok) {
      throw new ApiError("MembershipApi.requestMembership", response)
    }
  }

  async acceptMembershipRequest(membershipRequestId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${membershipRequestId}/accept`,
    )
    if (!response.ok) {
      throw new ApiError("MembershipApi.acceptMembershipRequest", response)
    }
  }

  async rejectMembershipRequest(membershipRequestId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${membershipRequestId}/reject`,
    )
    if (!response.ok) {
      throw new ApiError("MembershipApi.rejectMembershipRequest", response)
    }
  }

  async getPendingRequestsByEntity(entityId: string): Promise<MembershipRequestsResult> {
    const response: ApiResponse<MembershipRequestsResult> = await this.api.apisauce.get(
      `${API_PATH}entity/${entityId}/pending`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("MembershipApi.getPendingRequestsByEntity", response)
    }
    return response.data
  }

  async searchOrganizationMembers(
    organizationId: string,
    query: MembershipQuery,
  ): Promise<OrganizationMembersSearchResult> {
    const response: ApiResponse<OrganizationMembersSearchResult> = await this.api.apisauce.post(
      `${API_PATH}organization/${organizationId}`,
      query,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("MembershipApi.searchOrganizationMembers", response)
    }
    return response.data
  }

  async searchGroupMembers(
    groupId: string,
    query: MembershipQuery,
  ): Promise<GroupMembersSearchResult> {
    const response: ApiResponse<GroupMembersSearchResult> = await this.api.apisauce.post(
      `${API_PATH}group/${groupId}`,
      query,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("MembershipApi.searchGroupMembers", response)
    }
    return response.data
  }

  async leaveOrganization(organizationId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}organization/${organizationId}/leave`,
    )
    if (!response.ok) {
      throw new ApiError("MembershipApi.leaveOrganization", response)
    }
  }

  async leaveGroup(groupId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}group/${groupId}/leave`,
    )
    if (!response.ok) {
      throw new ApiError("MembershipApi.leaveGroup", response)
    }
  }

  async removeUserMembership(
    userId: string,
    organizationId: string,
    groupId?: string,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(`${API_PATH}remove-user`, {
      userId,
      organizationId,
      groupId,
    })
    if (!response.ok) {
      throw new ApiError("MembershipApi.removeUserMembership", response)
    }
  }
}
